import React from "react";
import { Link } from "gatsby";
import { FaDownload, FaLinkedin } from "react-icons/fa";

const DesktopResumeContainer = () => {
  return (
    <section className="bg-box-bg p-[5%] text-[0.8rem] text-dark-grey">
      <div className="flex items-baseline justify-between text-white">
        <h2 className="text-[2.1rem] font-semibold">Olujimi</h2>

        <span className="flex items-center justify-between gap-x-3 text-lg md:gap-x-4 md:text-[0.8rem]">
          <a
            href="https://linkedin.com/in/adebakin-olujimi-25446331b"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-x-1"
          >
            <FaLinkedin />
            <span className="hidden md:flex">LinkedIn</span>
          </a>
          <a
            href="https://drive.google.com/drive/folders/19DWyndTzE3bEq8mPMEIaU7PnEDUESpnT?usp=sharing"
            // GOOGLE DRIVE RESUME LINK
            target="_blank"
            download
            className="flex items-center gap-x-1"
          >
            <FaDownload />
            <span className="hidden md:flex">Download</span>
          </a>
        </span>
      </div>
      <b className="my-[6px] text-cfcfcf">Frontend Developer</b>
      <p className="mb-4 text-dark-grey md:mt-6">
        I am a passionate and driven frontend developer with an insatiable
        curiosity for innovation and a commitment to delivering excellence.
        Seeking an entry-level opportunity, I bring a blend of creativity and
        technical expertise to dynamic and forward-thinking teams. My experience
        centers around building intuitive, responsive, and visually compelling
        web applications. With a strong focus on user experience, I specialize
        in translating complex designs into seamless, pixel-perfect interfaces
        while ensuring optimal performance and accessibility. Beyond
        development, I have a keen eye for modern UI trends and a dedication to
        adopting best practices in web development. Elevate your digital
        presence—I am not just a developer; I am a creator of impactful user
        experiences.
      </p>

      <h3 className="my-4 flex items-center font-semibold text-e2e2e2">
        <p className="mr-2.5 font-[Andika] capitalize">Frontend Development</p>
        <span className="h-px grow bg-[#77777859]"></span>
      </h3>
      <p>
        HTML, CSS, Bootstrap, JavaScript, Typescipt, Tailwind, React, Nextjs,
        RESTful API, Web Application development, Web Design, User Experience,
        Git, Github, Web Hosting.
      </p>
      <h3 className="my-4 flex items-center font-semibold text-e2e2e2">
        <p className="mr-2.5 font-[Andika] capitalize">Extras</p>
        <span className="h-px grow bg-[#77777859]"></span>
      </h3>
      <p>Node js, Firebase, Supabase, MongoDB.</p>
      <h3 className="my-4 flex items-center font-semibold text-e2e2e2">
        <p className="mr-2.5 font-[Andika] capitalize">Soft Skills</p>
        <span className="h-px grow bg-[#77777859]"></span>
      </h3>
      <p className="text-[0.8rem]">
        Good Communication, Teamwork, Critical Thinking, Adaptability, Solving
        Skills, Decision-making, Consistent, Creativity, Emotional intelligence.
      </p>

      {/* ***************** WORK STARTS HERE *********** */}

      <h3 className="my-4 flex items-center font-semibold text-e2e2e2">
        <p className="mr-2.5 font-[Andika] capitalize">Work Experience</p>
        <span className="h-px grow bg-[#77777859]"></span>
      </h3>
      <p className="text-base text-white">
        Spin Designs - <i>Frontend Developer</i>
      </p>
      <p className="my-3 ml-[5px] text-xs">September 2024 - PRESENT</p>
      <ul className="list-box my-3 space-y-2">
        <li>
          Effectively converted Figma designs into fully functional web
          applications and websites, ensuring a seamless user experience.
        </li>
        <li>
          Collaborated with backend developers to integrate RESTful APIs,
          enabling dynamic content and smooth functionality on the website.
        </li>
        <li>
          Maintained and enhanced front-end platforms built with HTML, CSS,
          JavaScript, and React.
        </li>
      </ul>

      <p className="text-base text-white">
        DevCent - <i>Frontend Development Instructor</i>
      </p>
      <p className="my-3 ml-[5px] text-xs">September 2024 - PRESENT</p>
      <ul className="list-box my-3 space-y-2">
        <li>Trained students on front-end development skills.</li>
        <li>
          Provided guidance to students during and after the training, and
          continued skill enhancement.
        </li>
        <li>Encouraged teamwork and communication skills.</li>
      </ul>

      <p className="text-base text-white">
        Kmarvi - <i>Frontend Developer</i>
      </p>
      <p className="my-3 ml-[5px] text-xs">September 2024 - PRESENT</p>
      <ul className="list-box my-3 space-y-2">
        <li>
          Developed responsive and user-friendly web interfaces that align with
          modern design standards.
        </li>
        <li>
          Collaborated with cross-functional teams to deliver seamless user
          experiences and efficient code solutions.
        </li>
        <li>
          Optimized websites for performance, accessibility, and mobile
          responsiveness.
        </li>
        <li>
          Implemented innovative features to enhance functionality while
          ensuring clean, maintainable code.
        </li>
      </ul>

      <h3 className="my-4 flex items-center text-[0.8rem] font-semibold text-e2e2e2">
        <p className="mr-3 basis-1/12 font-[Andika] capitalize">Projects</p>
        <span className="h-px basis-10/12 bg-[#77777859]"></span>
      </h3>
      <p className="text-[0.8rem]">
        Links to my works can be found on{" "}
        <Link to="/work" className="text-white">
          /work
        </Link>{" "}
        and more details can be provided upon request.
      </p>
    </section>
  );
};

export default DesktopResumeContainer;
